import React from "react";
import {UiScope} from "@bloomreach/ui-extension-saas";
import CKeditorField from "./CKeditorField";

interface CmsFieldState {
    value: string
    editMode: boolean
    config: any
}

interface CmsFieldProperties {
    ui: UiScope
}


export default class CmsField extends React.Component<CmsFieldProperties, CmsFieldState> {

    constructor(props: CmsFieldProperties) {
        super(props);

        const config = JSON.parse(props.ui.extension.config);

        this.state = {
            config: config,
            editMode: false,
            value: "",
        }
    }

    componentDidMount() {
        this.getInitialValue(this.props.ui)
            .then(value => this.setState({value: value}, () => this.props.ui.document.field.setHeight("auto")));
    }

    async getInitialValue(ui: UiScope) {
        try {
            const brDocument = await ui.document.get();
            this.setState({editMode: brDocument.mode === 'edit'});
            return await ui.document.field.getValue();
        } catch (error: any) {
            console.error('Failed to register extension:', error.message);
            console.error('- error code:', error.code);
        }
        return '';
    }

    // async openDialog(ui: UiScope, value: string) {
    //     try {
    //         const dialogOptions: DialogProperties = {
    //             title: 'Code',
    //             url: './dialog',
    //             size: DialogSize.Medium,
    //             value: value
    //         };
    //
    //         const response = await ui.dialog.open(dialogOptions) as unknown as string;
    //         this.setState({value: response});
    //         await ui.document.field.setValue(encode(response));
    //     } catch (error: any) {
    //         if (error.code === 'DialogCanceled') {
    //             return;
    //         }
    //         console.error('Error after open dialog: ', error.code, error.message);
    //     }
    //
    // }


    render() {
        const {value, config, editMode} = this.state;

        return (
            <CKeditorField
                config={config}
                data={value}
                onChange={(data) => {
                    this.props.ui.document.field.setValue(data)
                    this.props.ui.document.field.setHeight("auto");
                }}
                editMode={editMode}
                // onOpenDialog={(value) => this.openDialog(this.props.ui, value)}
            />);
    }
}


