import React from 'react';
import './App.css';
// import {AppBar, Box, Drawer, IconButton, styled, Toolbar, useTheme} from "@mui/material";
import {Box} from "@mui/material";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import {EditorConfig} from "@ckeditor/ckeditor5-core";
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const {Editor} = require('ckeditor5-custom-build-free-edition/build/ckeditor');

console.info('removable plugins', Object.keys(Editor._removablePlugins).map(value => value))
console.info('default config', Editor.defaultConfig);

export const testConfig = {
    removePlugins: ['Markdown'], toolbar: {
        "items": [
            "undo",
            "redo",
            "findAndReplace",
            "|",
            "heading",
            "style",
            "|",
            "bold",
            "italic",
            "underline",
            "removeFormat",
            "|",
            "strikethrough",
            "superscript",
            "subscript",
            "|",
            "alignment",
            "bulletedList",
            "numberedList",
            "outdent",
            "indent",
            "|",
            "htmlEmbed",
            "codeBlock",
            "code",
            "sourceEditing",
            "|",
            "link",
            "imageInsert",
            "mediaEmbed",
            "blockQuote",
            "specialCharacters",
            "horizontalLine",
            "pageBreak",
            "insertTable",
            "selectAll",
            "|",
            "fontBackgroundColor",
            "fontColor",
            "fontFamily",
            "fontSize",
            "highlight",
            "|",
            "textPartLanguage"
        ]
    },
    style: {
        definitions: [
            {
                name: 'Article category',
                element: 'h3',
                classes: ['category']
            },
            {
                name: 'Info box',
                element: 'p',
                classes: ['info-box']
            },
        ]
    }
}

// const drawerWidth = 240;

interface FieldProperties {
    onChange: (data: string) => void
    // onOpenDialog: (data: string) => void
    editMode: boolean
    data: string
    config: { config: EditorConfig, users?: [], style?: string } | any
}

// const DrawerHeader = styled('div')(({theme}) => ({
//     display: 'flex',
//     alignItems: 'center',
//     padding: theme.spacing(0, 1),
//     // necessary for content to be below app bar
//     ...theme.mixins.toolbar,
//     justifyContent: 'flex-start',
// }));

function CKeditorField({onChange, editMode, data, config = {}}: FieldProperties) {

    // const theme = useTheme();
    // const [open, setOpen] = React.useState(false);
    //
    // const handleDrawerOpen = () => {
    //     setOpen(true);
    // };
    //
    // const handleDrawerClose = () => {
    //     setOpen(false);
    // };

    return (
        <Box paddingBottom={'10px'} margin={0}>
            <style
                dangerouslySetInnerHTML={{__html: `.ck-editor__editable_inline, .ck-source-editing-area, .ck-editor__editable {min-height: 150px;}`}}/>
            {/*<DrawerHeader />*/}
            <CKEditor
                disabled={!editMode}
                editor={Editor}
                config={config.config}
                data={data}
                onChange={(event, editor) => {
                    //@ts-ignore
                    console.log(editor.getData());
                    //@ts-ignore
                    onChange(editor.getData())
                }}
            />
            {/*<AppBar variant={"outlined"} color="primary" >*/}
            {/*    <Toolbar> */}
            {/*        <Box sx={{ flexGrow: 1 }} />*/}
            {/*        <IconButton*/}
            {/*            color="inherit"*/}
            {/*            aria-label="open drawer"*/}
            {/*            edge="end"*/}
            {/*            size={'small'}*/}
            {/*            onClick={handleDrawerOpen}*/}
            {/*            sx={{...(open && {display: 'none'})}}*/}
            {/*        >*/}
            {/*            {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}*/}
            {/*        </IconButton>*/}
            {/*    </Toolbar>*/}
            {/*</AppBar>*/}
            {/*<Drawer*/}
            {/*    sx={{*/}
            {/*        width: drawerWidth,*/}
            {/*        flexShrink: 0,*/}
            {/*        '& .MuiDrawer-paper': {*/}
            {/*            width: drawerWidth,*/}
            {/*        },*/}
            {/*    }}*/}
            {/*    variant="persistent"*/}
            {/*    anchor="right"*/}
            {/*    open={open}*/}
            {/*>*/}
            {/*    <DrawerHeader>*/}
            {/*        <IconButton onClick={handleDrawerClose}>*/}
            {/*            {theme.direction === 'rtl' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}*/}
            {/*        </IconButton>*/}
            {/*    </DrawerHeader>*/}
            {/*</Drawer>*/}
        </Box>
    );
}

export default CKeditorField;
